import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/Seo"




const NotFoundPage = () => {
  const seo = {
    "meta": [
        {
            "property": "og:image",
            "content": "/static/4b6bb20aceec244ce998d985501f6b75/Official_logo.svg"
        },
        {
          "property": "og:image:alt",
          "content": "Sightfull"
        }
    ],
    "title": "Page not found",
    "description": "The Sightfull platform helps SaaS companies leverage the full power of their business data in order to quickly analyze and optimize their revenue flows."
}
  return (
    <Layout showCta={false} showMenus={false}>
      <Seo {...seo} />
      <div className={"pt-32 pb-12  lg:pt-48 lg:pb-96 -bg-blue "}>

        <div className="h-48 flex flex-row ">


          <div className="grow bg-no-repeat bg-right" style={{ backgroundImage: 'url(/background/404-b.png)' }}></div>
          <div className="text-center pt-12">

            <h1 className="text-blue text-7xl px-12">404.</h1>

          </div>
          <div className=" grow bg-no-repeat bg-left" style={{ backgroundImage: 'url(/background/404-a.png)' }} ></div>
        </div>

        <div className="container flex flex-col text-center ">

          <div className=" lg:w-1/2 mx-auto ">
            <h2 className="text-5xl lg:text-6xl">Are you lost?</h2>
            <h4 className="my-10 font-normal">

            </h4>
            <p>
              ...not all those who wander are lost,<br /> please visit our home page

            </p>
            <div className="pt-12">
              <Link to={'/'} className="rounded-full text-sm bg-blue text-white font-bold py-3.5 px-11">back to home page</Link >
            </div>
          </div>

        </div>
      </div>
      <div>
      </div>
    </Layout >
  )
}


export default NotFoundPage

// export const Head = () => <title>Page not found</title>







